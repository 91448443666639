import axios from 'axios'
import { ElMessage,ElMessageBox } from 'element-plus'
import router from '../router'
import { domain } from './url'



const instance = axios.create({
  baseURL:domain.baseUrl,
  timeout: 10000
})


instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    ElMessage.error('出现了一个错误，请联系客服')
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  response => {
    var result = response.data;
    if (!result.success) {
      switch (result.errorCode) {
        case 500:
          ElMessage.error('内部出错了，请联系客服')
          break;
        case 413:
          ElMessage({
            message: '很抱歉，您没有该操作权限.',
            type: 'warning'
          })
          break;
        case 401:
          ElMessage(result.msg + ",将在3s后跳转回登陆页...")
          setTimeout(() => {
            router.push('/')
          }, 3000);
          break;
        case -1:
          ElMessage(result.msg + ",将在3s后跳转回登陆页...")
          setTimeout(() => {
            router.push('/')
          }, 3000);
          break;
        default:
          ElMessage({
            message: result.msg,
            type: 'warning'
          })
          break;
      }
    }

    return response.data;
  },
  error => {
    ElMessage.error('出现了一个错误，请联系客服')
    return Promise.reject(error)
  }
)
export default instance