export default function(app:any){
    app.directive('hasPer', {
        mounted( el: any, binding: any ) {
            const {value} = binding;
            let permison=localStorage.getItem('plist')||'';
            let t=JSON.parse(permison);
            let  flt= t.some((item:any) =>item.frontPaths==value[0]);
            if(!flt){
                el.parentNode && el.parentNode.removeChild(el);
            }
        }
    })
}