import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  //  {
  //   path: '/',
  //   name: 'Home',
  //   component: () => import('../views/Home.vue')
  // },
   {
    path: '/',
    name: 'Login',
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/home',
    name: '首页',
    component: () => import('../views/home/index.vue'),
    redirect:'/main',
    children:[
      {
        path:'/keybode',
        name:'钥匙码',
        component:()=>import('../views/keybode/index.vue')
      },
      {
        path:'/user',
        name:'用户列表',
        component:()=>import('../views/user/index.vue')
      },
      {
        path:'/role',
        name:'角色列表',
        component:()=>import('../views/role/index.vue')
      },
      {
        path:'/permission',
        name:'权限管理',
        component:()=>import('../views/permission/index.vue')
      },
      {
        path:'/main',
        name:'欢迎页',
        component:()=>import('../views/home/main.vue')
      },
      {
        path:'/article',
        name:'文章列表',
        component:()=>import('../views/article/index.vue')
      }, 
      {
        path:'/category',
        name:'分类管理',
        component:()=>import('../views/category/index.vue')
      },
      {
        path:'/message',
        name:'留言管理',
        component:()=>import('../views/message/index.vue')
      },
      {
        path:'/log',
        name:'日志管理',
        component:()=>import('../views/logs/index.vue')
      },
      {
        path: '/:pathMatch(.*)*',
        component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue')
      },
      {
        path: '/erro',
        component: () => import(/* webpackChunkName: "about" */ '../views/erro.vue')
      }
    ]

  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from) => {
  // ...
  // 返回 false 以取消导航
  console.log(to)
})

export default router
