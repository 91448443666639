const menu = {
    state:{
        menuList: [{
            path: '',
            name: '用户管理',
            children: [{
                path: '/user',
                name: '用户列表'
            }, {
                path: '/role',
                name: '用户列表'
            }]
        }]
    },
    getters: {
        getMenulist(state:any) {
            return state.menuList;
        }
    },
    //同步修改值
    mutations: {
        MENULIST(state:any,value:any){
            state.menuList=value;
            //组件调用  this.$store.commit('menu/MENULIST')
        }
    },
    //这里是异步修改值，为了devtool追踪修改的值
    actions: {

    },
}

export default menu