
import { createStore } from 'vuex'
import login from './modules/login'
import menu from './modules/menu'
export default createStore({
  modules: {
     login,
     menu
  }
})


