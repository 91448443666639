import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import https from './request/https'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import registPermission from './util/directive/permission'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { domain } from './request/url'

const app =createApp(App)
 
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.config.globalProperties.$baseUrl=domain.baseUrl;
app.config.globalProperties.$https=https;
registPermission(app);
app.use(store)
    .use(router)
    .use(ElementPlus,{
        locale:zhCn
    })
    .mount('#app')



