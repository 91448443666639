const login = {
    state: {
        isLogin: false
    },
    getters: {

    },
    //同步修改值
    mutations: {

    },
    //这里是异步修改值，为了devtool追踪修改的值
    actions: {

    },
}

export default login